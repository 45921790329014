 export function formatDate (publishdate) {
    const dtOptions = {
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute:'2-digit'
    }
    let date = new Date(publishdate).toUTCString();
    let localDate = new Date(`${date} UTC`);
    return localDate.toLocaleString("default", dtOptions);
  };
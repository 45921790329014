export const buildProxyURL = (url) => {
  let apiURL = url;
  if (process.env.NODE_ENV !== 'production') {
    apiURL = `http://localhost:5000/proxy/?url=${encodeURIComponent(url)}`;
  }
  return apiURL;
};

export function fetchSearchResults(page, searchTerm, typeFilter, pubFilter) {
  let term = encodeURIComponent(searchTerm);
  let rows = 20;
  let batch = (page - 1) * rows;
  let base = `solrpxy.prod.ftw`;
  let params = [
    `q=${term}`,
    `fl=title+objectid+summary+publishdate+publication+contenttype+originatingurl`,
    `fq=state%3Apublished`,
    `fq=publication%3A${pubFilter}`,
    `fq=publishdate%3A[*+TO+NOW]`,
    `fq=contenttype%3A(${typeFilter})`,
    `hl=on&rows=${rows}`,
    `sort=publishdate+desc`,
    `wt=json`,
    `start=${batch}`
  ]
  let query = params.join('&');
  let irl_url = `http://${base}.nandomedia.com:8983/solr/escenic/select?${query}`;

  console.log(irl_url);

  const url = 'https://t68tv0b55f.execute-api.us-east-1.amazonaws.com/next-app/solr-query';
  const headers = new Headers({
    'query':`${query}`
  });
  const res = fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((res) => res.json())
    .then((data) => ({ data, query }));
  return res;
}

export function fetchPostsInExperiment(objectids) {
  let base = `solrpxy.prod.ftw`;
  let irl_url = `http://${base}.nandomedia.com:8983/solr/escenic/get?${objectids}`;
  console.log(irl_url);

  const url = 'https://t68tv0b55f.execute-api.us-east-1.amazonaws.com/next-app/solr-get';
  const headers = new Headers({
    'get':`${objectids}`
  });
  const res = fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((res) => res.json())
    .then((data) => ({ data, objectids }))
    .catch((error) => ({ error, objectids }));
  return res;
}

export function fetchPost(id) {
  const url = 'https://t68tv0b55f.execute-api.us-east-1.amazonaws.com/next-app/mrcontent-article';
  const irl_url = `http://rpre648p.prod.ftw.nandomedia.com:9090/mrcontent/v2/content/${id}}`;
  console.log(irl_url);
  
  const headers = new Headers({
    'article':`${id}`
  });
  const res = fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((res) => res.json())
    .then((data) => ({ data, id }))
    .catch((error) => ({ error, id }));
  return res;
}


/* download button */
export function fetchDownloadResults(searchTerm, typeFilter, pubFilter) {
  let term = encodeURIComponent(searchTerm);
  let rows = 1000;
  let base = `solrpxy.prod.ftw`;
  let params = [
    `q=${term}`,
    `fl=objectid+title+publication+publishdate+contenttype+originatingurl`,
    `fq=state%3Apublished`,
    `fq=publication%3A${pubFilter}`,
    `fq=publishdate%3A[*+TO+NOW]`,
    `fq=contenttype%3A(${typeFilter})`,
    `hl=on&rows=${rows}`,
    `sort=publishdate+desc`,
    `wt=json`,
    `start=0`
  ]
  let query = params.join('&');
  let irl_url = `http://${base}.nandomedia.com:8983/solr/escenic/select?${query}`;
  console.log(irl_url);

  const url = 'https://t68tv0b55f.execute-api.us-east-1.amazonaws.com/next-app/solr-query';
  const headers = new Headers({
    'query':`${query}`
  });
  const res = fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((res) => res.json())
    .then((data) => ({ data, query }));
  return res;
}

export function fetchDownloadExperiments(objectids) {
  let base = `solrpxy.prod.ftw`;
  let irl_url = `http://${base}.nandomedia.com:8983/solr/escenic/get?${objectids}`;
  console.log(irl_url);

  const url = 'https://t68tv0b55f.execute-api.us-east-1.amazonaws.com/next-app/solr-get';
  const headers = new Headers({
    'get':`${objectids}`
  });
  const res = fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((res) => res.json())
    .then((data) => ({ data, objectids }));
    console.log(res);
  return res;
}
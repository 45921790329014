import React from 'react';
import Auth, { useAuth } from './utils/auth.js';
import Page from './components/Page/index.js';
// import SheetThing from './components/SheetThing';
import './App.scss';

function App() {
  return (
    <Auth>
      <>
        <Page />
      </>
    </Auth>
  );
}

export default App;

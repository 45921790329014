// TODO: get pubs list from https://api.prod.nandomedia.com/mrcontent/v2/publication/
const pubs = [
  ['accesousa'],
  ['bellinghamherald'],
  ['bnd'],
  ['bradenton'],
  ['centredaily'],
  ['charlotteobserver'],
  ['elnuevoherald'],
  ['fresnobee'],
  ['heraldonline'],
  ['heraldsun'],
  ['idahostatesman'],
  ['islandpacket'],
  ['kansas'],
  ['kansascity'],
  ['kentucky'],
  ['ledger-enquirer'],
  ['losbanosenterprise'],
  ['macon'],
  ['mahoningmatters'],
  ['mcclatchydc'],
  ['mcclatchy-partners'],
  ['mcclatchy-wires'],
  ['mercedsunstar'],
  ['miamiherald'],
  ['modbee'],
  ['myrtlebeachonline'],
  ['ncinsider'],
  ['newsobserver'],
  ['sacbee'],
  ['sanluisobispo'],
  ['star-telegram'],
  ['sunherald'],
  ['thenewstribune'],
  ['theolympian'],
  ['thestate'],
  ['tri-cityherald'],
];

const types = [
  ['story', 'story'],
  ['wirestory', 'wirestory'],
  ['blogpost', 'blogpost'],
  ['embedInfographic', 'embed'],
  ['html', 'html'],
  ['gallery', 'gallery'],
  ['newsmail_edition', 'newsletter'],
  ['picture', 'photo'],
  ['topic', 'topic'],
  ['videoIngest', 'video'],
];

let projects = [];
let experiments = [];

let projectStorage = window.localStorage;

function parseSheetRows(rows) {
  console.log(rows, 'length: ', rows.length);
  projects = [];
  experiments = [];
  rows.forEach((row, i) => {
    let project = {};
    if (i > 0) {
      project.id = row.values[0]?.formattedValue;
      project.name = row.values[1]?.formattedValue;
      project.program = row.values[2]?.formattedValue;
    
      if (row.values.length === 4 && row.values[3].formattedValue) {
        let posts = row.values[3].formattedValue.split(',');
        project.posts = posts;
      } else {
        project.posts = [];
      }
      console.log(project);
      projects.push(project);
    }
  });
  console.log('projects: ', JSON.stringify(projects));
  projectStorage.setItem('projects', JSON.stringify(projects));

  projects.forEach(function (item) {
    experiments.push([item.name]);
  });
}

function getSheetValues() {
  let SHEET_ID = process.env.REACT_APP_SHEET_ID;
  if (process.env.NODE_ENV !== 'production') {
    SHEET_ID = process.env.REACT_APP_SHEET_ID_DEV;
  }
  const PATH = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}?includeGridData=true`;

  console.log('sheet id:', SHEET_ID);
  window.gapi?.client
    .request({
      path: PATH,
      method: 'GET',
    })
    .then(
      function (response) {
        const rows = response.result.sheets[0].data[0].rowData;
        parseSheetRows(rows);
      },
      function (reason) {
        console.log('Error: ' + reason.result.error.message);
      }
    );
}

export { projectStorage, pubs, types, projects, getSheetValues, experiments };

// external dependencies
import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
  Fragment,
} from 'react';
import debounce from 'lodash.debounce';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// styles
import './Posts.scss';
import '../../Filters.scss';
import logo from '../../images/next-logo.png';

// internal utils and config
import {
  projectStorage,
  pubs,
  types,
  projects,
  getSheetValues,
  experiments,
} from '../../config/data.js';
import { FilterIDs } from '../../config/filters.js';
import { 
  fetchSearchResults, 
  fetchPostsInExperiment, 
  fetchPost,
  fetchDownloadResults, 
  fetchDownloadExperiments } from '../../utils/api.js';
import { filterHandlers } from '../../utils/filters.js';

// internal components
import FilterGroup from '../FilterGroup/index.js';
import ContentCard from '../ContentCard/index.js';
import { exp } from 'prelude-ls';
import { id } from 'apicache';
//import Post from '../Post';

export default function Posts({ data }) {
  const input = useRef();
  const firstUpdate = useRef(true);

  const [view, setView] = useState('default');

  const [typeFilter, setTypeFilter] = useState('story');
  const [pubFilter, setPubFilter] = useState('*');
  const [expFilter, setExpFilter] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);

  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [downloadResults, setDownloadResults] = useState(null);

  const [post, setPost] = useState(null);
  

  const prev = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
      setNativeInput(page - 1);
    }
  };
  const next = () => {
    let totalPages = results.data?.response.numFound/20;
    if (page < totalPages){
      setPage(page + 1);
      setNativeInput(page + 1);
    }
  };
  const jumpToPage = () => {
    setPage(parseInt(inputPage));
    setInputPage(page);
  };
  const setNativeInput = (num) => {
    const el = document.getElementById('select-page');
    if (el) {
      el.value = num;
    }
  }

  const debouncedSearchCallback = useCallback(
    debounce(
      async (search) => await callSearchAPI(page, search, typeFilter, pubFilter),
      500
    ),
    [page, typeFilter, pubFilter]
  );

  const debouncedTypeFilterCallback = useCallback(
    debounce(
      async (typeFilter) =>
        await callSearchAPI(page, searchTerm, typeFilter, pubFilter),
      500
    ),
    [page, searchTerm, pubFilter]
  );

  const debouncedPubFilterCallback = useCallback(
    debounce(
      async (pubFilter) =>
        await callSearchAPI(page, searchTerm, typeFilter, pubFilter),
      500
    ),
    [page, searchTerm, typeFilter]
  );

  const debouncedExpFilterCallback = useCallback(
    debounce(
      async (expFilter) => await callExperimentsAPI(expFilter, '*', '*'),
      500
    ),
    [page, searchTerm]
  );

  const handleSearch = (event) => {
    let val = event.target.value;
    setResults('');
    setDownloadResults('');
    if (view === 'manage-experiments') {
      setPost('');
      setTypeFilter('story');
      setPubFilter('*');
    }

    setView('default');
    if (val === '') {
      firstUpdate.current = true;
    } else {
      firstUpdate.current = false;
    }
    setSearchTerm(val);
    debouncedSearchCallback(val);
  };

  const filterState = useMemo(() => {
    return {
      [FilterIDs.ContentTypes]: [
        typeFilter,
        setTypeFilter,
        debouncedTypeFilterCallback,
      ],
      [FilterIDs.Publications]: [
        pubFilter,
        setPubFilter,
        debouncedPubFilterCallback,
      ],
      [FilterIDs.Experiments]: [
        expFilter,
        setExpFilter,
        debouncedExpFilterCallback,
      ],
    };
  }, [
    typeFilter,
    pubFilter,
    expFilter,
    debouncedTypeFilterCallback,
    debouncedPubFilterCallback,
    debouncedPubFilterCallback,
  ]);

  const [selectedExp, setSelectedExp] = useState('');
  const handleChangeFilter = (id, filter, checked) => {
    const [filters, setFilters, filterCallback] = filterState[id];
    const query = filterHandlers[id](filter, checked, filters);
    setFilters(query);

    if (view === 'manage-experiments') {
      setSelectedExp(filter);
    }

    if (filterCallback) {
      filterCallback(query);
      setPage(1);
      setNativeInput(1);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggle = (id) => {
    if (menuOpen === id) {
      setMenuOpen(false);
    } else {
      setMenuOpen(id);
    }
  };

  const [postSelected, setPostSelected] = useState(false);
  const handlePostSelection = (id, target) => {
    if (setPostSelected === id) {
      setPostSelected(false);
    } else {
      setPostSelected(id);
    }
  };
  
  function modifyDownloadResults(downloadResults){
    let modifiedResults = downloadResults;
    const docs = downloadResults.data.response.docs;
    let docsMod = modifiedResults.data.response.docs;
    for (const doc in docs){
      const publication = docs[doc].publication;
      const objectid = docs[doc].objectid;
      const url = `https://www.${publication}.com/article${objectid}.html`;
      docsMod[doc].originatingurl = url;  
    }
    
    return modifiedResults;
  }

  function updateSheetValues(projects) {
    let rows = [];

    projects.forEach((project, i) => {
      let row = {
        values: [
          {
            userEnteredValue: {
              stringValue: project.posts.toString(),
            },
          },
        ],
      };
      rows.push(row);
    });

    let SHEET_ID = process.env.REACT_APP_SHEET_ID;
    if (process.env.NODE_ENV !== 'production') {
      SHEET_ID = process.env.REACT_APP_SHEET_ID_DEV;
    }
    const PATH = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}:batchUpdate`;
    window.gapi?.client
      .request({
        path: PATH,
        method: 'POST',
        body: JSON.stringify({
          requests: [
            {
              updateCells: {
                rows: rows,
                fields: '*',
                start: {
                  sheetId: 0,
                  rowIndex: 1,
                  columnIndex: 3,
                },
              },
            },
          ],
        }),
      })
      .then(
        function (response) {
          console.log(response.result);
        },
        function (reason) {
          console.log('Error: ' + reason.result.error.message);
        }
      );
  }

  const handleProjectList = (post_id, project, checked) => {
    console.log(`Add ${post_id} to ${project}: `, checked);

    let proj_data = projects.find((x) => x.id === project);

    if (checked) {
      proj_data.posts.push(post_id);
    } else {
      proj_data.posts.forEach((x, i) => {
        if (x === post_id) {
          proj_data.posts.splice(i, 1);
        }
      });
    }
    console.log(projects);
    projectStorage.setItem('projects', JSON.stringify(projects));
    updateSheetValues(projects);
  };

  const processFetchResults = (jsonResults) => {
    if (jsonResults.data.error) {
      console.log('error: ', jsonResults.data.error);
      throw new Error(jsonResults.data.error.msg);
    } else if (jsonResults.data.errorMessage) {
      console.log('Solr error: ', jsonResults.data.errorMessage);
      throw new Error(jsonResults.data.errorMessage);
    }

    setIsLoading(false);
    setError(false);
    setResults(jsonResults);
    setPost('');
  }

  const catchFetchErrors = (e) => {
    let error_text = 'Oh no!'
    if (e.toString().indexOf("Failed to fetch") > -1) {
      error_text = 'Are you connected to the VPN?';
    } else if (e.toString().indexOf("timed out") > -1) {
      error_text = 'Oh no! Solr timed out. Try again later.';
    } else {
      error_text = 'Oh no! Please try a different search.'
    }
    setIsLoading(false);
    setError(error_text);
  }

  const callSearchAPI = (page, searchTerm, typeFilter, pubFilter) => {
    if (searchTerm !== '') {
      setIsLoading(true);

      fetchSearchResults(page, searchTerm, typeFilter, pubFilter)
        .then((jsonResults) => {
          processFetchResults(jsonResults);
        })
        .catch((e) => {
          catchFetchErrors(e);
        });
    } else {
      setError(false);
      if (view === 'manage-experiments') {
        setResults('');
      }
    }
  };

  const callExperimentsAPI = (expFilter) => {
    if (expFilter !== null){
      fetchPostsInExperiment(expFilter)
        .then((jsonResults) => {
          processFetchResults(jsonResults);
        })
        .catch((e) => {
          catchFetchErrors(e);
        });
    } else {
      setResults('none');
    }
  }

  useEffect( () => {
    if (view === 'manage-experiments') {
      callExperimentsAPI(expFilter);
    } else {
      callSearchAPI(page, searchTerm, typeFilter, pubFilter);
    }
  }, [page]);

  const loadPost = (id) => {
    console.log(id);

    fetchPost(id)
      .then((jsonResults) => {
        console.log('post fetch worked');

        if (!jsonResults.data) {
          throw new Error(jsonResults.error);
        }

        if (!jsonResults.data.url) {
          console.log(jsonResults.data.errorMessage);
          setPost('');
        } else {
          setError(false);
          setPost(jsonResults);
        }
      })
      .catch((e) => {
        console.log('post fetch didnt work');
        setError(e);
      });
  };

  // scroll preview panel to top when new post loads
  useEffect(() => {
    document.getElementById('preview-panel').scrollTo(0, 0);
  }, [post])

  const cleanHTML = (html) => {
    const cleanHTML = DOMPurify.sanitize(html, {
      USE_PROFILES: { html: true },
    });

    return cleanHTML;
  };

  useEffect(() => {
    input.current.focus();
    getSheetValues();
  }, []); // empty dependency only runs once on initial render

  const formatDate = (publishdate) => {
    const dtOptions = {
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute:'2-digit'
    }
    let date = new Date(publishdate).toUTCString();
    let localDate = new Date(`${date} UTC`);
    return localDate.toLocaleString("default", dtOptions);
  };

  const currentDateTime = () => {
    const dtOptions = {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric', 
      hour: '2-digit', 
      minute:'2-digit',
      second:'2-digit',
      hour12: false
    }
    let date = new Date();
    return date.toLocaleString("default", dtOptions).replace(', ', '_');
  };

  const createCSV = (downloadResults) => {
    let indexes = ['objectid','title','publication','publishdate','contenttype','originatingurl'];
    if (downloadResults){
      let data = downloadResults.data.response.docs;
      let CSV = '';
      //extract each row
      for (var i = 0; i < data.length; i++) {
        var row = '';

        //extract each column and convert to comma-seprated string
        for (var index in data[i]) {
          if (indexes.includes(index)){
            row += '"' + data[i][index] + '",';
          }
        }
        row.slice(0, row.length - 1);
        CSV += row + "\r\n"; //add a line break after each row
      }

      let csvFile = new Blob([CSV], {type: 'text/csv'});
      let href = window.URL.createObjectURL(csvFile);
      

      let a = document.createElement('a');
      if (view === 'manage-experiments') {
        const exp = document.querySelector("#experiments-filter input[type='radio']:checked").id.toLowerCase().replace(' ', '-');
        a.download = `cat-${exp}-${currentDateTime()}.csv`;
      } else {
        a.download = `cat-search-${currentDateTime()}.csv`;
      }
      a.href = href;

      return a.click();
    }
  }

  const lookupTypeName = (contenttype) => {
  // console.log('type: ', contenttype);
    const match = types.find(type => type[0] === contenttype);
    if (match){
   //   console.log('match: ', match);
      return match[1];
    }
  }

  const openInCUE = (url, title) => {
    if (url) {
      let currentStory = url;
      let storyMatch = currentStory.match(/article(\d+).html/);
      if (storyMatch === null) {
        storyMatch = currentStory.match(/article(\d+).ece/);
      }
      if (typeof storyMatch !== 'undefined') {
        let storyID = storyMatch[1];
        let titleEncoded = encodeURIComponent(title);
        const cueBase =
          'https://cue.misitemgr.com/#main?name=' +
          titleEncoded +
          '&uri=https%3A%2F%2Fcue-webservice.misitemgr.com%2Fwebservice%2Fescenic%2Fcontent%2F' +
          storyID +
          '&mimetype=x-ece%2Fstory';
        return cueBase;
      } else {
        console.log('Unable to find a CUE link for this post.');
      }
    }
  };

  function removeDuplicates(arr) { 
    return arr.filter((item, 
        index) => arr.indexOf(item) === index); 
        console.log(removeDuplicates(arr));
  }

  const showExperimentMenu = () => {};

  let isReady = !isLoading && !error;

  return (
    <Fragment>
      <nav className="nav-bar">
        <div>
          <img
            src={logo}
            width="136"
            alt="NEXT: News Experimentation Team"
            onClick={(e) => {
              window.location.reload();
            }}
          />
        </div>
        <div>
          <input
            id="content-search"
            ref={input}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            autoComplete="off"
          />
        </div>
        <div>
          {view === 'default' && (
            <a
              href="#"
              className="button reverse"
              onClick={(e) => {
                setView('manage-experiments');
                setSearchTerm('');
                setPubFilter('*');
                setResults('');
                setError('');
                setPost('');
                setPage(1);
              }}
            >
              Manage Experiments
            </a>
          )}
          {view === 'manage-experiments' && (
            <a
              href="#"
              className="button reverse"
              onClick={(e) => {
                setView('default');
                setSearchTerm('');
                setPubFilter('*');
                setResults('');
                setPost('');
                setPage(1);
              }}
            >
              Search Content
            </a>
          )}
        </div>
      </nav>
      {view === 'default' && (
        <section id="default-view" className="main">
      
          <div id="filters" className="col">
            {/* <h2>Filters</h2> */}
          
            <FilterGroup
              inputType="checkbox"
              id={FilterIDs.ContentTypes}
              filters={types}
              header="Content Type"
              defaultSelected={['story']}
              onChangeFilter={handleChangeFilter}
            />
            <FilterGroup
              inputType="checkbox"
              id={FilterIDs.Publications}
              filters={pubs}
              header="Publication"
              onChangeFilter={handleChangeFilter}
            />
          </div>
          
          
          <div id="content-list" className="col">
            {firstUpdate.current && !isLoading && !error && (
              <h1>Let's curate some content!</h1>
            )}

            {!firstUpdate.current && isLoading && <h1>Loading...</h1>}

            {!isLoading && error && <h1>{error}</h1>}

            {isReady && results && results.data?.response.numFound > 0 && (
              <header>
                <h1>
                  {results.data.response.numFound.toLocaleString()} results for
                  &ldquo;{searchTerm}&rdquo;
                </h1>
              
                <div>
                  <button 
                    className="button mini reverse" 
                    title = "Download CSV"
                    onClick={(e) => {
                      fetchDownloadResults(searchTerm, typeFilter, pubFilter)
                        .then((downloadResults) => {
                          console.log('download results: ', downloadResults)
                          if (downloadResults.data.error) {
                            console.log('error: ', downloadResults.data.error);
                            throw new Error(downloadResults.data.error.msg);
                          }
                          setDownloadResults(downloadResults);
                          createCSV(downloadResults)
                        })
                        .catch((e) => {
                          let error_text = 'Oh no!';
                          console.log(error_text);
                        });
                    }}
                    >
                    ↓
                  </button>
                </div>
              </header>
            )}

            {!firstUpdate.current &&
              isReady &&
              results &&
              results.data?.response.numFound === 0 && (
                <h1>No results found – try another search!</h1>
              )}

            {isReady && results && results.data?.response.numFound > 0 && (
              <section>
                {results &&
                  results.data.response.docs.map((post) => (
                    <ContentCard 
                      post={post} 
                      key={post.objectid}
                      loadPost={loadPost}
                      handleToggle={handleToggle}
                      menuOpen={menuOpen}
                      projects={projects}
                      handleProjectList={handleProjectList}>
                    </ContentCard>
                  ))}
              </section>
            )}

            <nav className="posts-nav">
              {results && results.data.response.numFound !== 0 && (
                <>
                  <button
                    className="reverse"
                    onClick={prev}
                    disabled={isLoading}
                  >
                    Prev
                  </button>

                  <div className="posts-nav-position">
                    Page 
                    <input 
                      id="select-page" 
                      defaultValue={page}
                      onChange={e => setInputPage(e.target.value)}
                      type="number"
                    /> 
                    of {Math.ceil(results.data.response.numFound / 20).toLocaleString()} 
                    <button 
                      type="submit"
                      onClick={jumpToPage}
                    >
                      Go
                    </button>
                  </div>
                 
                  <button
                    className="reverse"
                    onClick={next}
                    disabled={isLoading}
                  >
                    Next
                  </button>
                </>
              )}
            </nav>
          </div>
          <div id="preview-panel" className="col">
            <Fragment>
              {/* <h2>Post Panel</h2> */}
              {post && (
                <nav className="post-actions">
                  <button className="cta">Add to Experiment</button>
                  {/* <button>Notes</button> */}
                  <div>
                    <a href={post.data.url} className="button" target="_blank">
                      <button>Live</button>
                    </a>
                    {['story', 'wirestory', 'embedInfographic', 'videoIngest', 'html'].includes(post.data.asset_type) && (
                    <a
                      href={openInCUE(post.data.url, post.data.title)}
                      className="button"
                      target="_blank"
                    >
                      <button>CUE</button>
                    </a>
                    )}
                  </div>
                  <ul>
                    {projects &&
                      projects.map((project) => (
                        <li key={project.id}>
                          <input
                            type="checkbox"
                            id={`d-panel-${post.objectid}-${project.id}`}
                            name={project.id}
                            value={project.id}
                            defaultChecked={
                              project.posts.includes(post.objectid)
                                ? 'checked'
                                : ''
                            }
                            onChange={(e) =>
                              handleProjectList(
                                post.objectid,
                                project.id,
                                e.target.checked
                              )
                            }
                          />
                          <label
                            htmlFor={`d-panel-${post.objectid}-${project.id}`}
                          >
                            {project.name}
                          </label>
                        </li>
                      ))}
                  </ul>
                </nav>
              )}
              {post && (
                <Fragment>
                <article className="preview">
                  <section className="post-info">
                    <span>{lookupTypeName(post.data.asset_type)}</span>
                    <span>{post.data.publication}</span>
                    <time>{formatDate(post.data.published)}</time>
                  </section>
                  <h2>{post.data.title}</h2>
                  <div className="byline">
                    {parse(cleanHTML(post.data.byline))}
                  </div>
                  {/* <img src="" width="100" alt="thumbnail image" /> */}
                  {parse(cleanHTML(post.data.body))}
                  {parse(cleanHTML(post.data.intro_text))}

                  {post.data.asset_type === 'picture' && (
                    <figure>
                      
                      <img src={post.data.image_url} alt={post.data.caption} width="100%" />
                      <figcaption>{parse(cleanHTML(post.data.caption))}</figcaption>
                    </figure>
                  )}

                  {post.data.asset_type === 'videoIngest' && (
                    <figure>
                      <Fragment>
                        <a href={post.data.url} target="_blank">
                          <img 
                            className="cnx-video-tag"  
                            src={`//img.connatix.com/pid-561b5a99-2f7d-4c56-ad46-18ca5f9ad5a1/${post.data.videoCMSID}/1_th.jpg?crop=1080:608,smart&amp;format=jpeg&amp;quality=60&amp;fit=crop`} 
                          />
                        </a>
                      </Fragment>                
                    </figure>
                  )}

                  {post.data.asset_type === 'embedInfographic' && (
                    <pre>
                      <code>{post.data.embed_code}</code>
                    </pre>
                  )}
                  {post.data.asset_type === 'html' && (
                  <pre>
                    <code>{post.data.body}</code>
                  </pre>
                  )}
                </article>
                <div id="more-post-data">
                  <h4>MORE POST DATA</h4>
                  <table> 
                    <tbody>
                      <tr>
                    <th>Content ID: </th>
                  <td> {post.id}</td>
                  </tr>
                    <tr>
                      <th>URL: </th>
                      <td><a href={`${post.data.url}`} target = "_blank">{post.data.url}</a></td>
                    </tr>
                    
                    <tr>
                    <th> Meta Data: </th>
                    </tr>
                    <tr><th>Asset Type</th><td>{post.data.asset_type}</td></tr>
                    <tr><th>Sections</th><td>{post.data.sections}</td></tr>
                    <tr><th>Home</th><td>{post.data.home}</td></tr>
                    <tr><th>Publication</th><td>{post.data.publication}</td></tr>
                    <tr><th>Last Modified</th><td>{post.data.last_modified}</td></tr>
                    <tr><th>Updated</th><td>{post.data.updated}</td></tr>
                    <tr><th>Created</th><td>{post.data.created}</td></tr>
                   {/* <tr><th>State</th><td>{post.data.state}</td></tr> */}
                    <tr><th>Keywords</th><td>{post.data.keywords}</td></tr>
                    <tr><th>Priority</th><td>{post.data.priority}</td></tr>
                    <tr><th>Layout Variant</th><td>{post.data.layout_variant}</td></tr>
                    <tr><th>Byline</th><td>{post.data.byline}</td></tr>
                    <tr><th>Legacy Summary</th><td>{post.data.legacy_summary}</td></tr>
                    <tr><th>Endnote</th><td>{post.data.endnote}</td></tr>
                    <tr><th>Meta Title</th><td>{post.data.meta_title}</td></tr>
                    <tr><th>Allow Comments</th><td>{post.data.allow_comments}</td></tr>
                    <tr><th>Originating URL</th><td>{post.data.originating_url}</td></tr>
                    <tr><th>Owner</th><td>{post.data.owner}</td></tr>
                    <tr><th>Allow Index</th><td>{post.data.allowIndex}</td></tr>
                    <tr><th>Allow Follow</th><td>{post.data.allowFollow}</td></tr>
                    <tr><th>Byline Credit</th><td>{post.data.byline_credit}</td></tr>
                    <tr><th>Allow Ads</th><td>{post.data.allow_ads}</td></tr>
                    <tr><th>Source</th><td>{post.data.source}</td></tr>
                    <tr><th>Allow STN Player</th><td>{post.data.allow_stn_player}</td></tr>
                    <tr><th>Story Teaser</th><td>{post.data.story_teaser}</td></tr>
                    <tr><th>Allow Teaser</th><td>{post.data.allow_teasers}</td></tr>
                    <tr><th>Allow TTS</th><td>{post.data.allow_tts}</td></tr>
                    <tr><th>Disable AMP</th><td>{post.data.disable_amp}</td></tr>
                    <tr><th>Lead Video Priority</th><td>{post.data.leadvideo_priority}</td></tr>
                    <tr><th>Tags</th><td>{post.data.tags}</td></tr>
                    <tr>
                      <th>Authors</th>
                      <td>
                        {post.data.authors?.map((author, index) => (
                          <div key={index}>
                            {author.name} {author.email}
                          </div>
                        ))}
                    </td>
                    </tr>
                    <tr>
                      <th>Hum Fields</th>
                      <td>
                        {Object.entries(post.data.hum_fields)?.map((entries, index) => (
                          <div key={index}>
                          <p><strong> {entries[0]}: </strong> {entries[1]} </p>  
                          
                          </div>
                        ))}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </section>
      )}
      {view === 'manage-experiments' && (
        <section id="manage-experiments" className="main">
          <div id="filters" className="col">
            {/* <h2>Filters</h2> */}
            

            <FilterGroup
              id={FilterIDs.Experiments}
              filters={experiments}
              header="Experiments"
              onChangeFilter={handleChangeFilter}
            />
          </div>
          <div id="content-list" className="col">
            {!isLoading && error && <h1>{error}</h1>}
            {isReady && results && results !== "none" && (
              <header>
                <h1>{results.data.response?.numFound} posts in {selectedExp}</h1>
              
                <div>
                <button 
                    className="button mini reverse" 
                    title = "Download CSV"
                    onClick={(e) => {
                      fetchDownloadExperiments(expFilter)
                        .then((downloadResults) => {
                          console.log('download results: ', downloadResults)
                          if (downloadResults.data.error) {
                            console.log('error: ', downloadResults.data.error);
                            throw new Error(downloadResults.data.error.msg);
                          }
                          const modifiedResults = modifyDownloadResults(downloadResults);
                          setDownloadResults(modifiedResults);
                          createCSV(modifiedResults);
                        })
                        .catch((e) => {
                          let error_text = 'Oh no!';
                          console.log(error_text);
                        });
                    }}
                    >
                    ↓
                  </button>
                </div>
              </header>
            )}
            {isReady && results && results.data?.response.numFound === 0 && (
              <h1>No posts in {selectedExp}</h1>
            )}
            {isReady && results && results !== "none" && (
              <section>
                {results &&
                  results.data.response.docs.map((post) => (
                    <ContentCard 
                      post={post} 
                      key={post.objectid}
                      loadPost={loadPost}
                      handleToggle={handleToggle}
                      menuOpen={menuOpen}
                      projects={projects}
                      handleProjectList={handleProjectList}>
                    </ContentCard>
                  ))}
              </section>
            )}

            
          </div>
          <div id="preview-panel" className="col">
            <Fragment>
              {/* <h2>Post Panel</h2> */}
              {post && (
                <nav className="post-actions">
                  <button className="cta">Add to Experiment</button>
                  {/* <button>Notes</button> */}
                  <div>
                    <a href={post.data.url} className="button" target="_blank">
                      <button>Live</button>
                    </a>
                    {['story', 'wirestory', 'embedInfographic', 'videoIngest', 'html'].includes(post.data.asset_type) && (
                    <a
                      href={openInCUE(post.data.url, post.data.title)}
                      className="button"
                      target="_blank"
                    >
                      <button>CUE</button>
                    </a>
                    )}
                  </div>
                </nav>
              )}
              {post && (
                <article className="preview">
                  <section className="post-info">
                    <span>{post.data.asset_type}</span>    
                    <span>{post.data.publication}</span>
                    <time>{formatDate(post.data.published)}</time>
                  </section>
                  <h2>{post.data.title}</h2>
                  <div className="byline">
                    {parse(cleanHTML(post.data.byline))}
                  </div>
                  {/* <img src="" width="100" alt="thumbnail image" /> */}
                  {parse(cleanHTML(post.data.body))}
                  {parse(cleanHTML(post.data.intro_text))}

                  {post.data.asset_type === 'picture' && (
                    <figure>
                      <img src={post.data.image_url} alt={post.data.caption} width="100%" />
                      <figcaption>{parse(cleanHTML(post.data.caption))}</figcaption>
                    </figure>
                  )}

                  {post.data.asset_type === 'videoIngest' && (
                    <figure>
                      <Fragment>
                        <a href={post.data.url} target="_blank">
                          <img 
                            className="cnx-video-tag"  
                            src={`//img.connatix.com/pid-561b5a99-2f7d-4c56-ad46-18ca5f9ad5a1/${post.data.videoCMSID}/1_th.jpg?crop=1080:608,smart&amp;format=jpeg&amp;quality=60&amp;fit=crop`} 
                          />
                        </a>
                      </Fragment>                
                    </figure>
                  )}

                  {post.data.asset_type === 'embedInfographic' && (              
                    <pre>
                      <code>{post.data.embed_code}</code>
                    </pre>
                    
                  )}
                   {post.data.asset_type === 'html' && (
                    <pre>
                      <code>{post.data.body}</code>
                    </pre>
                  )}
                  
                </article>
              )}
            </Fragment>
          </div>
        </section>
      )}
    </Fragment>
  );
}

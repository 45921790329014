import { FilterIDs } from '../config/filters.js';
import { projects } from '../config/data.js';

export const filterHandlers = {
  [FilterIDs.ContentTypes]: (type, checked, typeFilter) => {
    console.log('typefilter: ', type, checked);
    let types = typeFilter;
    const operator = '+OR+';

    let typesArray = types.split(operator);

    //remove '*' from array
    typesArray = typesArray.filter((item) => item !== '*');

    let inArray = typesArray.includes(type);

    if (inArray && !checked) {
      //remove type from array
      typesArray.forEach((item, i) => {
        if (item === type) {
          typesArray.splice(i, 1);
        }
      });
      if (typesArray.length === 0) {
        typesArray.push('*');
      }
    } else if (!inArray && checked) {
      //add type to array
      typesArray.push(type);
    }

    let typesQuery = '';
    typesArray.forEach((item, i) => {
      if (i !== typesArray.length - 1) {
        typesQuery += item + operator;
      } else {
        typesQuery += item;
      }
    });

    return typesQuery;
  },
  [FilterIDs.Publications]: (pub, checked, pubFilter) => {
    console.log('pubfilter: ', pub, checked);
    let pubs = pubFilter;
    const operator = '+OR+';

    let pubsArray = pubs.split(operator);

    //remove '*' from array
    pubsArray = pubsArray.filter((item) => item !== '*');

    let inArray = pubsArray.includes(pub);

    if (inArray && !checked) {
      //remove pub from array
      pubsArray.forEach((item, i) => {
        if (item === pub) {
          pubsArray.splice(i, 1);
        }
      });
      if (pubsArray.length === 0) {
        pubsArray.push('*');
      }
    } else if (!inArray && checked) {
      //add pub to array
      pubsArray.push(pub);
    }

    let pubsQuery = '';
    pubsArray.forEach((item, i) => {
      if (i !== pubsArray.length - 1) {
        pubsQuery += item + operator;
      } else {
        pubsQuery += item;
      }
    });

    return pubsQuery;
  },
  [FilterIDs.Experiments]: (experiment, checked, expFilter) => {
    console.log('experimentsfilter: ', experiment, checked, expFilter);

    const checked_project = projects.find((x) => x.name === experiment);
    const project_ids = checked_project.posts;
    const operator = '&';
    const prefix = 'id=article%3A';

    console.log(project_ids);

    let expQuery = '';
    project_ids.forEach((item, i) => {
      if (i !== project_ids.length - 1) {
        expQuery += prefix + item + operator;
      } else {
        expQuery += prefix + item;
      }
    });

    if (expQuery === '') {
      expQuery = null;
    }

    console.log(expQuery);

    return expQuery;
  },
};

import React, { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth.js';

// refresh token
import { refreshTokenSetup } from '../../utils/refreshToken.js';

import logo from '../../images/next-logo.png';

function Login() {
  const { onAccessToken, logout, isAuthenticated } = useAuth();
  let access_token;

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';
  const DISCO_URL =
    'https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest';

  // GIS replaces gapi for authentication
  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://accounts.google.com/gsi/client';
    tag.addEventListener('load', function () {
      initClient();
    });
    document.body.appendChild(tag);
  }, []);

  // GAPI is still necessary for interacting with Google Sheets
  // https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#libraries_and_modules
  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://apis.google.com/js/api.js';
    tag.addEventListener('load', function () {
      gapiLoad();
    });
    document.body.appendChild(tag);
  }, []);

  const [GoogleAuth, setGoogleAuth] = useState(null);
  function initClient() {
    // Initialize the gapi.client object, which app uses to make API requests.
    // Get API key and client ID from API Console.
    // 'scope' field specifies space-delimited list of access scopes.
    let client;
    client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      discoveryDocs: [DISCO_URL],
      scope: SCOPE,
      callback: (tokenResponse) => {
        access_token = tokenResponse.access_token;
        console.log('token: ', access_token);
        
        let user = new Promise(function(resolve, reject) {
          let request = new XMLHttpRequest();
          const url = `https://www.googleapis.com/oauth2/v3/userinfo`;
          request.addEventListener("loadend", function() {
              const response = JSON.parse(this.responseText);
              if (this.status === 200) {
                  resolve(response);
              } else {
                  reject(this, response);
              }
          });
          request.open("GET", url, true);
          request.setRequestHeader('Authorization', `Bearer ${access_token}`);
          request.send();
        });

        user.then(function(response) {
          console.log(response);
        }, function(errorMessage) {
          console.log(errorMessage);
        });
          
        setSigninStatus(access_token);
        onAccessToken(access_token);

      },
    });
    
    setGoogleAuth(client);
  }

  function gapiLoad(){
    let gapi;
    gapi = window.gapi;
    gapi.load('client', function(){
        gapi.client.init({
      }).then(function() {
        gapi.client.load('sheets', 'v4');
      }).then(function(response) {
        console.log('discovery document loaded');
      }, function(reason) {
        console.log('Error: ' + reason.result.error.message);
      });
    });
  }

  function handleAuthClick(e, client) {
    console.log('e', e.target);
    console.log('client: ', client);

    if (e.target.id === 'sign-in-or-out-button'){
      client.requestAccessToken();
    } else {
      window.google.accounts.oauth2.revoke(access_token, () => {console.log('access token revoked')});
      logout();
    }
  }

  function revokeAccess(gauth) {
    gauth?.disconnect();
    logout();
  }

  function setSigninStatus(access_token) {
    let user = window.google.accounts.oauth2;
    let isAuthorized = user.hasGrantedAllScopes();

    onAccessToken(access_token);
    console.log('logged in: ', isAuthorized);
    console.log(isAuthenticated);
  }

  function updateSigninStatus() {
    console.log('signin status updated');
    let user = window.gapi.auth2.getAuthInstance().currentUser.get();
    setSigninStatus(user);
  }


  return (
    <>
      {!isAuthenticated && (
        <div id="login-container">
          <img src={logo} alt="NEXT" />
          <h1>Content Audit Tool</h1>
          <button
            id="sign-in-or-out-button"
            onClick={(e) => {
              handleAuthClick(e, GoogleAuth);
            }}
          >
            Log in with Google
          </button>
        </div>
      )}
      {isAuthenticated && (
        <div id="logout-container">
          <button
            id="sign-out-button"
            onClick={(e) => {
              handleAuthClick(e, GoogleAuth);
            }}
          >
            Log Out
          </button>
        </div>
      )}
    </>
  );
}

export default Login;

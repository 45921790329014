import Auth, { useAuth } from '../../utils/auth.js';
import Login from '../Login/index.js';
import Posts from '../Posts/index.js';

export default function Page({ data }) {
  const { onAccessToken, getHeaders, isAuthenticated } = useAuth();
  const onlySearch = process.env.REACT_APP_SEARCH;

  console.log('isAuthenticated:', isAuthenticated);

  return (
    <div
      id="container"
      className={`${isAuthenticated ? 'logged-in' : 'logged-out gradient-bg'} ${onlySearch ? 'search-only' : ''}`}
    >
      <Login />
      {isAuthenticated && <Posts />}
    </div>
  );
}

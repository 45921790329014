import { useRef } from "react";

export default function FilterGroup({
  inputType = 'radio',
  id,
  filters,
  header,
  defaultSelected = [],
  onChangeFilter,
})

 {
  const filterRef = useRef(null);
  function toggleFilters(){
    const header = filterRef.current;
    const classes = header.classList;
   
    classes.toggle('collapsed');
  }

  function value(filter){
    return filter[0];
  }
  function label(filter) {
    return (filter[1] ? filter[1] : filter[0]);
  }

  return (
    <>
    
      <h4 ref={filterRef} onClick={toggleFilters}>{header}<i className="arrow"></i></h4>
      <ul id={id}>
        {filters &&
          filters.map((filter) => (
            <li key={filter}>
              <input
                type={inputType}
                id={value(filter)}
                name={id}
                value={value(filter)}
                defaultChecked={defaultSelected.includes(value(filter))}
                onChange={(e) => onChangeFilter(id, value(filter), e.target.checked)}
              />
              <label htmlFor={value(filter)}>{label(filter)}</label>
            </li>
          ))}
      </ul>
    </>
  );
}

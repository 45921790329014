import { formatDate } from '../../utils/utils.js';

export default function ContentCard({ 
  post,
  loadPost,
  handleProjectList,
  handleToggle,
  projects,
  menuOpen,
}) {
    return (
      <article id={post.objectid} className="result">
        <div
          className="summary-container"
          onClick={(e) => {
            loadPost(post.objectid);
          }}
        >
          <h3>{post.title}</h3>
          <p>{post.summary}</p>
          <span>{post.contenttype}</span>
          <span>{post.publication}</span>  
          <time>{formatDate(post.publishdate)}</time>
        </div>
        <div
          className={`menu-container ${menuOpen === post.objectid ? 'open' : ''}`}
        >
          <button className="mini" onClick={() => handleToggle(post.objectid)}>
            {menuOpen === post.objectid ? '-' : '+'}
          </button>
          <ul>
            <li>Add to Experiment</li>
            {projects &&
              projects.map((project) => (
                <li key={project.id}>
                  <input
                    type="checkbox"
                    id={project.id}
                    name={project.id}
                    value={project.id}
                    defaultChecked={
                      project.posts.includes(post.objectid) ? 'checked' : ''
                    }
                    onChange={(e) =>
                      handleProjectList(
                        post.objectid,
                        project.id,
                        e.target.checked
                      )
                    }
                  />
                  <label htmlFor={project.id}>{project.id}</label>
                </li>
              ))}
          </ul>
        </div>
      </article>
    );
  }
  
  
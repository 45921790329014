import React, { useState, useContext } from 'react';

const AuthContext = React.createContext();

export default function Auth({ children }) {
  const authState = useState(null);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const [auth, setAuth] = useContext(AuthContext);

  const onAccessToken = (token) => {
    setAuth({ token });
  };

  const logout = () => {
    setAuth(null);
  };

  const getHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
  };

  return { onAccessToken, logout, getHeaders, isAuthenticated: !!auth?.token };
}
